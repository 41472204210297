import React, { useEffect, useState } from "react"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  useSearchBox,
  useHits,
} from "react-instantsearch-hooks-web"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/reusable/Wrapper"
import Seo from "../components/Seo"
import Button from "../components/reusable/Button"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const queryHook = (query, search) => {
  search(query)
}

function SearchBox(props) {
  const [term, setTerm] = useState()
  const [searchSubmitted, setSearchSubmitted] = useState(false)
  const { refine } = useSearchBox({
    queryHook,
  })

  const urlParams = new URLSearchParams(window.location.search)
  const query = urlParams.get("q")
  // save the query in the state
  const [queryState, setQueryState] = useState(query)

  useEffect(() => {
    if (queryState) {
      setTerm(query)
      setSearchSubmitted(true)
      setQueryState(null)
    } else {
      if (searchSubmitted) {
        refine(term)
        window.history.pushState({}, "", `?q=${term}`)
        setSearchSubmitted(false)
        return
      }
    }
  }, [queryState, term, refine, query, searchSubmitted])

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        refine(term)
        setSearchSubmitted(true)
      }}
    >
      <input
        placeholder="What are you looking for?"
        minLength={2}
        required
        defaultValue={term}
        onChange={e => setTerm(e.target.value)}
      ></input>
      <Button
        text="Search"
        buttonStyle="primary"
        colour="orange"
        button={true}
        type="submit"
      />
    </form>
  )
}

function formatModifiedDate(date) {
  if (!date) return null
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

export default function Search() {
  function Hits(props) {
    const { hits, results } = useHits(props)
    const [page, setPage] = useState(1)
    const itemsToShow = 10

    if (!results.query) return null

    const hitsToShow = hits.slice(
      page * itemsToShow - itemsToShow,
      page * itemsToShow
    )

    return (
      <div className="search-results">
        <h4>
          {hits.length} result{hits.length > 1 ? "s" : ""} for{" "}
          <strong>'{results.query}'</strong>
        </h4>
        {hitsToShow.map(hit => (
          <div key={hit.title} className="result">
            <h5>
              <Link to={hit.uri}>{hit.title}</Link>
            </h5>
            <p>Last Modified - {formatModifiedDate(hit.modified)}</p>
          </div>
        ))}
        <Pagination
          items={hits}
          page={page}
          setPage={setPage}
          itemsToShow={itemsToShow}
        />
      </div>
    )
  }

  function Pagination({ items, page, setPage, itemsToShow }) {
    const totalPages = [...Array(Math.ceil(items.length / itemsToShow))].map(
      _ => items.slice(0, itemsToShow)
    )

    function paginationHandler(index) {
      setPage(index + 1)
      // Scroll to top of results
      document.querySelector(".search-results").scrollIntoView({
        behavior: "smooth",
      })
    }

    if (totalPages.length <= 1) return null

    return (
      <ul class="search-pagination">
        {totalPages.map((p, index) => {
          return (
            <li key={index}>
              <button
                className={`${index + 1 === page ? "active" : ""}`}
                onClick={e => paginationHandler(index)}
              >
                {index + 1}
              </button>
            </li>
          )
        })}
      </ul>
    )
  }
  return (
    <Layout
      title="Search Our Site | Mitchells & Butlers"
      meta={{
        metaDescription:
          "Use our search function to find exactly what you’re looking for on the Mitchells & Butlers website, from our brands and values to our financial reports.",
        ogTitle: "Search Our Site",
        twitterTitle: "Search Our Site",
        ogDescription:
          "Use our search function to find exactly what you’re looking for on the Mitchells & Butlers website, from our brands and values to our financial reports. Tap to start your search...",
        twitterDescription:
          "Use our search function to find exactly what you’re looking for on the Mitchells & Butlers website, from our brands and values to our financial reports. Tap to start your search...",
      }}
      canonical={`/search/`}
    >
      <div className={`search-page-title`}>
        <Wrapper>
          <h1>Search</h1>
        </Wrapper>
      </div>
      <Wrapper>
        <InstantSearch searchClient={searchClient} indexName="Pages">
          <div className="search-form">
            <h4>Search the website</h4>
            <SearchBox />
          </div>
          <Hits />
        </InstantSearch>
      </Wrapper>
    </Layout>
  )
}
